import React from 'react';

import { navigate } from 'gatsby';

export default class EnglishRmtPage extends React.Component {
  componentDidMount() {
    navigate('/risk-management-measures');
  }

  render() {
    return <></>;
  }
}
